const jquery = require('jquery');

window.$ = jquery;
window.jQuery = jquery;
window.jquery = jquery;

module.exports = jquery;

require('jquery-ujs');
require('multiselect');
require('select2');
require('datatables.net-bs');
require('dsmorse-gridster/dist/jquery.dsmorse-gridster');
